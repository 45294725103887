import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Welcome from "../components/welcome"
import ConnectMore from "../components/connect-more"
import DiscoverMore from "../components/discover-more"
import FlexMore from "../components/flex-more"
import CareerMatchBanner from "../components/career-match-banner"
import AffordMore from "../components/afford-more"
import ThriveMore from "../components/thrive-more"
import SucceedMore from "../components/succeed-more"
import LiveMore from "../components/live-more"
import PlayMore from "../components/play-more"
import ExploreMore from "../components/explore-more"
import Campuses from "../components/campuses"
import SplashWelcome from "../components/splash-welcome"
import MainLayout from "../layouts/main"
import AcademicSessions from "../components/academic-sessions"

const PostEventPage: React.FC<PageProps> = () => (
  <MainLayout
    showNotifications={true}
    menuItems={[
      { label: `Ways to connect`, href: `#connect-more` },
      { label: `Academics`, href: `#discover-more` },
      { label: `Student life`, href: `#afford-more` },
      { label: `Our campuses`, href: `#campuses` },
    ]}
  >
    <main id="main">
      <Welcome />
      <ConnectMore />
      <DiscoverMore />
      <FlexMore />
      <CareerMatchBanner />
      <AffordMore />
      <ThriveMore />
      <SucceedMore />
      <LiveMore />
      <PlayMore />
      <ExploreMore />
      <Campuses />
    </main>
  </MainLayout>
)

export default PostEventPage

export const Head: HeadFC = () => (
  <title>Georgian College Virtual Open House</title>
)
